import React from "react";
import classes from "./Shared.module.scss";

const phone = (props) => {
  return (
    <a className={[classes.callLink, classes[props.where]].join(' ')} href="tel: +12519814772">{props.addText ? props.addText : null}{props.text}</a>
  );
}
phone.defaultProps = {
  text: "251.981.4772"
};
export default phone;
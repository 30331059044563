import React from "react";
import classes from './Social.module.scss';
import Email from "../Shared/Email";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import FacebookIcon from '@material-ui/icons/Facebook';
const emailText=(<span aria-label="Rejuvenation Med Spa Email Link" uk-icon="icon: mail"></span>);
const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.facebook.com/rejuvenationmedspaob/" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Rejuvenation Med Spa Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

      <li><a href="https://www.instagram.com/rejuvenationmedspa/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Rejuvenation Med Spa Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>
      
      {/* <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Rejuvenation Med Spa Twitter Link" uk-icon="icon: twitter"></span>
      </a></li> */}

      {props.email === "true" ? <li><Email where="socialBar" text={emailText} /></li> : null}
    </ul>
  );
};
social.defaultProps = {
  email: "false"
};
export default social;

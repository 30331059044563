import classes from './Services.module.scss';
import Tabs from "../../components/Tabs/Tabs";
import skincareHero from "../../assets/images/facialHero.jpg";
import laserHero from "../../assets/images/laserHero.jpg";
import laserStack1 from "../../assets/images/tripolloStack1.png";
import laserStack2 from "../../assets/images/laserAfter2.png";
import cosmeticHero from "../../assets/images/cosmeticIntro.jpg";
import massageHero from "../../assets/images/massageHero.jpg";
import massageGrid from "../../assets/images/massagePage.jpg";
import browHero from "../../assets/images/overhead2.jpg";
import eyebrows from "../../assets/images/eyebrowPage.jpg";
import AndSvg from "../../components/Shared/And";
import arrow from '../../assets/images/arrowC3.png';
import arrowL from '../../assets/images/arrowLt.png';

const arrowLink = (<img src={arrow} alt="ph alt" className={[classes.arrow, classes.color].join(' ')} />);
const arrowLinkL = (<img src={arrowL} alt="ph alt" className={[classes.arrow, classes.white].join(' ')} />);

const skinDesc="Rejuvenation Med Spa offers facials and body treatments to refresh and improve your skin, and help fight the effects of aging.";
const skinHd=(<>Amazing Face <AndSvg /> Body Treatments</>);
const skinSub=(<>For &middot; Women &middot; Men &middot; Teens</>);
const skinIntro=(
  <div className={classes.ctext}>
    <p>Our master aesthetician will consult with you about your goals, and analyze your skin. Together, you will establish treatments and home protocols to make your skin look and act healthy.</p>
    <p>We strive to ensure that each patient is thoroughly involved in determining the treatment plan to aid in their skin's anti-aging and rejuvenating process, 
    and is fully aware of what the process will entail.</p>
  </div>
);
const skinTab1content=(
  <ul className={[classes.treatmentUl, classes.faceUl].join(' ')}>
    <div className={classes.row1}>
      <li className={[classes.treatLi, classes.chem, classes.box2].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Custom</span> Chemical Peels<br /> <span className={classes.treatPrice}>$65-250</span></p>
          <p>Used to treat various skin conditions, and are available in different strengths from light to heavy. Peels are typically used to treat fine lines, 
          large pores, acne, acne scarring, and hyperpigmentation (sun spots). Your skin care professional can consult with you personally to determine which chemical peel is right 
          for you.</p>
          <p className={classes.bmargin0XX}><span className={classes.smaller}>*May be added to custom facial at additional charge.</span></p>
        </div>
      </li>

      <li className={[classes.treatLi, classes.hydro, classes.box3].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Hydro</span>facial<br /> <span className={classes.treatPrice}>$195 (face &amp; neck)</span> <br className={classes.showAll}/><span className={classes.print}> See clinic for pricing on other areas.</span></p>
          <p>Hydrofacial is a non-invasive treatment that not only mechanically removes dead surface skin, but infuses nutrients into the skin according to the skin's needs. It can be used on the face, neck, decollete, stomach, buttocks, or any area on the body needing reviving and resurfacing.</p>
        </div>
      </li>   
    </div>

    <div className={classes.row2}>
      <li className={[classes.treatLi, classes.oxy, classes.box4].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Oxygeneo</span> Rejuvenation Treatment<br /> <span className={classes.treatPrice}>$195</span></p>
          <p>"The Super Facial" ~ 3-in-1 Facial Treatment<br/>
          Immediate peeling and brightening, delivering a radiant, glowing, and firmed texture. Oxygen therapy for cellular 
          improvement. <a target="_blank" href="http://www.pollogen.com/pollogen-technology/oxygeno.html" rel="noreferrer" className={classes.textLink}>Click here</a> to 
          learn more.
          </p>
          <p className={classes.bmargin0XX}><span className={classes.smaller}>*May be added to custom facial at additional charge.</span></p>
        </div>
      </li>

      <li className={[classes.treatLi, classes.derma, classes.box5].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Derma</span>planing<br /> <span className={classes.treatPrice}>$90</span></p>
          <p>A treatment to remove the upper layer of dead cells from the skin, and remove hair from the epidermis. A Cleansing &amp; Appropriate mask for 
          your skin type will be part of the dermaplaning treatment. May also be performed in conjunction with a facial.</p>
          <p className={classes.bmargin0XX}><span className={classes.smaller}>*May be added to custom facial at additional charge.</span></p>						
        </div>
      </li>
    </div>

    {/* <li className={[classes.treatLi, classes.micro, classes.boxSolo].join(' ')}>
      <div className={classes.treatInner}>
        <p className={classes.treatName}><span className={classes.fancy}>Diamond</span> Microdermabrasion<br /> <span className={classes.treatPrice}>$80</span></p>
        <p className={classes.bmargin0XX}><span className={classes.smaller}>*May be added to custom facial at additional charge.</span></p>
      </div>
    </li> */}


    <div className={classes.rowMis}>
      <li className={[classes.treatLi, classes.needle, classes.boxLong5].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Microneedling</span> by SkinPen<br /> <span className={classes.treatPrice}>$300</span></p>
          <p>SkinPen is the first microneedling device cleared by the FDA. The treatment is designed to stimulate collagen by creating micro-injuries in the deeper layers of the skin to stimulate 
          the body's natural wound-healing process. The result: effective remodeling of scar tissue, smoothing out of fine-to-moderate lines and wrinkles, and reduction of redness, pores, and 
          hyperpigmentation (brown spots).</p>
          <p>This is normally done in a series of 3 treatments with little to no downtime.</p>
          <p><span className={classes.treatPrice0}>For an extra $150,</span> Human Growth Hormone may be added to this treatment to create more 
          stimulation post procedure.</p>
        </div>
      </li>

      <li className={[classes.treatLi, classes.micro, classes.boxShort6].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Diamond</span> Microdermabrasion<br /> <span className={classes.treatPrice}>$80</span></p>
          <p className={classes.bmargin0XX}><span className={classes.smaller}>*May be added to custom facial at additional charge.</span></p>
        </div>
      </li>
    </div>
    
  </ul>
);

const skinTab2content=(
  <ul className={[classes.treatmentUl, classes.bodyUl].join(' ')}>
    <div className={classes.row3}>
    <div className={[classes.col, classes.left].join(' ')}>
      <li className={[classes.treatLi, classes.fan, classes.bl1].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Fanny</span> Facial<br /><span className={classes.treatPrice}>$135</span></p>
          <p>Gear up your rear with this smooth and glow treatment. Perfect for bathing suit weather! Your backside will be exfoliated using microdermabrasion, to get rid of 
            dull, dead skin cells, as well as treated for congestion if needed. A mask is placed in the area to balance the skin. Your rear is left silky, hydrated, and smooth. </p>
        </div>
      </li>

      <li className={[classes.treatLi, classes.even, classes.bl2].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Oxygeneo</span> Rejuvenation Treatment<br /> <span className={classes.treatPrice}>$195</span></p>
          <p>Immediate peeling and brightening, delivering a radiant, glowing, and firmed texture. Oxygen therapy for cellular 
          improvement. <a target="_blank" href="http://www.pollogen.com/pollogen-technology/oxygeno.html" rel="noreferrer" className={classes.textLink}>Click here</a> to 
          learn more.
          </p>
        </div>
      </li>
    </div>

    <div className={[classes.col, classes.right].join(' ')}>
      <li className={[classes.treatLi, classes.sug, classes.br1].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Body</span> Scrub<br /><span className={classes.treatPrice}>$125</span></p>
          <p>Relax and enjoy a neck-to-toe body exfoliation, with pure salt or sugar scrubs, to rid your body of dull skin. Followed by a superbly hydrating moisturizer.</p>
        </div>
      </li>

      <li className={[classes.treatLi, classes.back, classes.br2].join(' ')}>
        <div className={classes.treatInner}>
          <p className={classes.treatName}><span className={classes.fancy}>Back</span> Treatment<br /><span className={classes.treatPrice}>$85-150</span></p>
          <p>Whether it is to generally improve the appearance of the back, or to treat acne or scars, a back treatment can improve the look of your skin on your back.</p>
        </div>
      </li>
    </div>
    </div>
  </ul>
);

const st1=(<><b>Facial</b><br/> Treatments</>)
const st2=(<><b>Body</b><br/> Treatments</>)
const st3=(<><b>Custom</b><br/> Facials</>)
const skinTab3=(
  <div className={[classes.treatmentUl, classes.cfUl].join(' ')}>
    {/* <div className={[classes.col, classes.grid50, classes.left].join(' ')}><img src={skincareImg2} alt="and" className={classes.cfImg} /></div> */}
    <div className={[classes.colXX, classes.grid45XX, classes.rightXX, classes.tmargXX, classes.cblock].join(' ')}>
      <p className={classes.hd}>Custom Facials: <span>$100-295</span></p>
      <ul className={classes.dotList}>
      <li><p className={classes.treatName}>Anti-Aging</p></li>
      <li><p className={classes.treatName}>Rosacea</p></li>
      <li><p className={classes.treatName}>Hydrating</p></li>
      <li><p className={classes.treatName}>Teen</p></li>
      <li><p className={classes.treatName}>Back</p></li>
      <li><p className={classes.treatName}>Deep Pore Cleansing</p></li>
      <li><p className={classes.treatName}>Oily/Acne &amp; Problematic</p></li>
      </ul>
    </div>
  </div>
);
const skinContent = (
  <Tabs 
    where="serviceSkin"
    stack="stackRight"
    tcontentWrap="med2Def"
    navAlign="dec"
    tnavWrap="fit"
    TESTENDS="STARTS AT XX options and ENDS RIGHT HERE"
    tab1title={st1}
    tab1content={skinTab1content}
    tab2title={st2}
    tab2content={skinTab2content} 
    tab3title={st3}
    tab3content={skinTab3}
  />
);


const laserDesc="Rejuvenation Med Spa provides effective TriPollar laser treatments to reverse the signs of aging and tighten skin.";
// const laserSubtitle=(<>Body Contouring &middot; Skin Tightening</>);
const laserSubtitle=(<>Body Contouring &middot; Skin Tightening &amp; Rejuvenation &middot; Hair Removal </>);
const laserIntro=(
  <div className={classes.ctext}>
    <p>TriPollar energy heats the skin from within, using <span>medically proven</span> radio-frequency energy 
      as a <span>painless</span> and <span>effective</span> method to reverse the signs of aging and tighten skin.</p>
    <a href="<https://youtu.be/7iDcnBTqomE" target="_blank" rel="noreferrer" className={classes.textLink}>Check out this video to learn more {arrowLink}{arrowLinkL}</a>
    <div className={classes.headline}><p>You won't <br className={classes.br1} /><span>believe</span> <br className={classes.br2} />our results!</p></div>
  </div>
);

const laserContent=(
  <div className={[classes.fullRow, classes.layout, classes.v2].join(' ')}>
    <div className={[classes.medDef, classes.mediaCust].join(' ')}>
      <div className={[classes.row, classes.row1].join(' ')}>
        <div className={[classes.col, classes.left, classes.grid50, classes.imageLay].join(' ')}><img src={laserStack1} alt="Before/after laser treatment on stomach" className={classes.image1} /></div>

        <div className={[classes.col, classes.right, classes.grid42, classes.textLay, classes.treatBlk].join(' ')}>
          <p className={classes.treatName}><span>Skin</span> tightening</p>
          <p>The internal heat causes collagen fibers to contract. This causes fibroblast cells to generate new collagen.</p>
        </div>
      </div>

      <div className={[classes.row, classes.row2].join(' ')}>
        <div className={[classes.col, classes.left, classes.grid50, classes.imageLay].join(' ')}><img src={laserStack2} alt="Effects of laser treatment on stretch marks" className={classes.image2} /></div>
        
        <div className={[classes.col, classes.right, classes.grid43, classes.textLay, classes.treatBlk, classes.tnc].join(' ')}>
          <p className={classes.treatName}><span>Fat</span> reduction</p>
          <p>The RF heats fat cells to make them reduce in size over a period of treatments. This technology allows specialized area contouring as well as skin tightening. This treatment may be used on face and body.</p>
        </div>
      </div>

      <div className={[classes.row, classes.rowSolo].join(' ')}>
        <div className={[classes.col, classes.long, classes.treatBlk].join(' ')}>
          <p className={classes.treatName}><span>Skin</span> Rejuvenation</p>
          <p className={classes.cat}>Hyperpigmentation (Brown Spots), Vein Treatment</p>
          <p>
            Advanced Skin Rejuvenation is a non-ablative (skin is neither removed or damaged) using pulsed light. This treatment targets unwanted blood vessels 
            and hyperpigmentation (brown spots). The process also restores the homogeneous skin color of your youth, and stimulates fibroblasts to produce collagen to 
            plump the skin from within. 1-3 treatments may be required.
          </p>
        </div>

        <div className={[classes.col, classes.short, classes.treatBlk].join(' ')}>
          <p className={classes.treatName}><span>Laser</span> Hair Removal</p>
          <p>Advanced hair reduction is a gentle pulsed light treatment that can be performed on the face, and anywhere on the body without downtime! Removes 
            unwanted hair safely and effectively on black, brown, and light brown hair. Multiple treatments are required to treat all of the hair growth cycles.
          </p>
        </div>
      </div>

      <div className={classes.imgRowMobile}>
        <div className={[classes.col, classes.left, classes.grid48].join(' ')}><img src={laserStack1} alt="Results of skin tightening rf laser" className={classes.image1} /></div>
        <div className={[classes.col, classes.right, classes.grid48].join(' ')}><img src={laserStack2} alt="Laser skin tightening - before and after" className={classes.image2} /></div>
      </div>
    </div>
  </div>
);


const cosDesc="Visit Rejuvenation Med Spa today for facial enhancements and line refinement. We offer BOTOX®, Juvéderm®, Jeuveau®, and V Soft Line® Threads.";
const cosSubtitle=(<>BOTOX<span className={classes.tm}>®</span> &middot; JUVÉDERM<span className={classes.tm}>®</span> &middot; V Soft Line<span className={classes.tm}>®</span> Threads</>);
const cosmeticIntro=(
  <div className={classes.ctext}>
    <p>We offer BOTOX<span className={classes.tm}>®</span>, Juvéderm<span className={classes.tm}>®</span>, Jeuveau<span className={classes.tm}>®</span>, and <br className={classes.vBr}/>
    V Soft Line<span className={classes.tm}>®</span> Threads. Our expert physician will consult with you to choose the best injection options for your facial enhancement and line 
    refinement goals. </p>
    <p>We strive to ensure that each patient is thoroughly involved in determining the treatment plan to aid in their skin's anti-aging and rejuvenating process, and is fully aware of what the process will entail.</p>
  </div>
);
const cosTab1content=(
  <div className={[classes.cet, classes.cet1].join(' ')}>
    <div className={[classes.row, classes.left, classes.pull].join(' ')}>
      <h4 className={classes.h4m}><b>Call for Pricing</b></h4>
      <p>BOTOX<span className={classes.tm}>®</span> Cosmetic works to block nerve impulses to injected muscles. As muscle contractions are reduced, so are the wrinkle lines of expression. BOTOX<span className={classes.tm}>®</span> Cosmetic, also known as onabotulinumtoxinA, is a purified protein that is produced by the bacterium Clostridium botulinum. It is manufactured by Allergan, Inc., and was FDA approved for cosmetic use in the United States in 2002 to improve the look of moderate to severe frown lines between the eyebrows (glabellar lines) in people 18 to 65 years of age. Off-label, it is commonly used by physicians to treat the horizontal forehead lines, the crow's feet on the side of the eyes, the bunny lines of the nose, chin dimpling, and other areas.</p>
    </div>

    <div className={[classes.row, classes.right, classes.pull].join(' ')}>
      <div className={[classes.col, classes.grid48, classes.left].join(' ')}><p>We set aside extra time for you to visit with the doctor, discuss your goals, concerns, and be carefully evaluated to determine your options. Should you decide to proceed with treatment, a small needle (30- to 32-gauge) will be delicately used to inject a few drops (0.01ml to 0.1ml) of reconstituted BOTOX<span className={classes.tm}>®</span> Cosmetic into muscle that causes the wrinkle. This eventually relaxes the muscle to reduce the creasing of overlying skin during facial expression. Over time, static wrinkle lines (the ones at rest) may relax and soften. BOTOX<span className={classes.tm}>®</span> relaxes muscle. It does not pull, nor does it fill the skin.</p></div>
      <div className={[classes.col, classes.grid48, classes.right].join(' ')}><p>BOTOX<span className={classes.tm}>®</span> treatment results typically last three months, though this may vary from three to six months depending on the individual. Ones muscle anatomy, muscle strength, size, skin quality and thickness, and physiology can affect the treatment outcome. It is recommended that you not be retreated in less than three months time. BOTOX<span className={classes.tm}>®</span> temporarily affects the nerve endings. Nerve impulses that trigger muscle contraction are blocked until the nerve endings undergo repair and resume normal function. This does not affect sensation or your ability to feel in the area of treatment.</p></div>
    </div>

    <div className={[classes.row, classes.left, classes.pull].join(' ')}>
      <p>It may take 7 to 10 days or longer (up to 30 days) for you to see maximum treatment results. Most people do not notice a significant difference the day after treatment. You may notice some muscle relaxation before this, but this will progress until the peak effect is reached. With repeated treatments, the beneficial effects may last longer. With regular use, BOTOX<span className={classes.tm}>®</span> may reversibly reduce the size of treated muscle until you begin using it. This is known as disuse atrophy and occurs naturally when a muscle is not exercised. This helps keep wrinkles slightly more relaxed during facial expression. At rest, the wrinkle itself may soften over time, because treated muscles do not constantly crease the skin. In essence, this allows the skin to repair the wrinkle.</p>
    </div>
    
    <div className={[classes.row, classes.right, classes.pull].join(' ')}>
      <div className={[classes.col, classes.grid53, classes.left].join(' ')}><p>The clinical use of BOTOX<span className={classes.tm}>®</span> is the result of more than 100 years of study into Botulinum Neurotoxin Type A (BoNTA). BOTOX<span className={classes.tm}>®</span> is one the world's most widely-researched medicines with approximately 2,300 publications in peer-reviewed scientific and medical journals. The amount of BOTOX<span className={classes.tm}>®</span> that is injected for cosmetic use is relatively small in comparison to the that which is typically used to treat patients suffering from debilitating medical conditions, such as severe neck muscle spasm, upper limb spasticity, and cerebral palsy. Although most other drugs you are likely to be familiar with are injected with ample quantity into muscle or vein to achieve therapeutic serum drug levels, only small amounts of BOTOX<span className={classes.tm}>®</span> are used to work locally around the sites they are injected into.</p></div>
      <div className={[classes.col, classes.grid43, classes.right].join(' ')}><p>
        Since BOTOX<span className={classes.tm}>®</span> Cosmetic is designed to work locally, the chance of this spreading to unintended areas is generally confined to adjacent muscle. There has not been a confirmed serious case of spread of toxin effect away from the injection site when BOTOX<span className={classes.tm}>®</span> has been used at the recommended dose to treat chronic migraine, severe underarm sweating, blepharospasm, or strabismus.
        BOTOX<span className={classes.tm}>®</span> should not be administered in women who are pregnant, breastfeeding, or in patients who are known to be allergic to the BOTOX<span className={classes.tm}>®</span>, its ingredients or to other botulinum toxin products such as Myoblock<span className={classes.tm}>®</span>, Dysport<span className={classes.tm}>®</span> or Xeomin<span className={classes.tm}>®</span>.</p>
      </div>
    </div>

    <div className={[classes.left, classes.pull, classes.grid85, classes.last].join(' ')}>
      <p>Jeuveau (Newtox) works similarly to BOTOX<span className={classes.tm}>®</span>, as it is injected into muscles to improve the look of moderate to severe frown lines between eyebrows, forehead, crows feet, and other areas of the face and neck.</p>
    </div>
  </div>
);
const cosTab2content=(
  <div className={[classes.cet, classes.cet2].join(' ')}>
    <div className={[classes.cblock, classes.sm].join(' ')}>
      <p>The JUVÉDERM<span className={classes.tm}>®</span> family of fillers are offered to sculpt and add volume to different areas of the face. Specific 
      JUVÉDERM<span className={classes.tm}>®</span> fillers are used to fill laugh lines, lips, cheeks, jawline, and lines above the lips.
      </p>
    </div>
  </div>);
const cosTab3content=(
  <div className={[classes.cet, classes.cet3].join(' ')}>
    <div className={[classes.cblock, classes.mid].join(' ')}>
      <p>V Soft Line<span className={classes.tm}>®</span> Threads are used to smooth wrinkled, crepey skin by replacing and adding collagen and elastin to damaged areas, in order to restore a healthy foundation under your skin. </p>
      <p>V Soft Line<span className={classes.tm}>®</span> Threads are sterile hypodermic cannulas preloaded with an absorbable PDO suture thread -- which is absorbable, and will build your own collagen and give you glowing skin through biostimulation. Most areas require 3 treatments, scheduled 4-6 weeks apart.</p>
    </div>
  </div>
);
const ct1=(<>Botox<em className={classes.tm}>®</em></>)
const ct2=(<>Juvéderm<em className={classes.tm}>®</em></>)
const ct3=(<>V Soft Line<em className={classes.tm}>®</em> Threads</>)

const cosmeticContent=(
  <Tabs 
    where="serviceCosmetic"
    XXtnavWrap="float"
    navAlign="dec"
    tnavWrap="fit"
    TESTENDS="STARTS AT XX options and ENDS RIGHT HERE"
    tab1title={ct1}
    tab1content={cosTab1content}
    tab2title={ct2}
    tab2content={cosTab2content}  
    tab3title={ct3}
    tab3content={cosTab3content}
  />
);


const massageSubtitle=(<>Relax &middot; Detox &middot; Destress</>);
const massageContent=(
  <div className={[classes.med2Def, classes.listWrap].join(' ')}>
    <div className={[classes.col, classes.right, classes.treatList].join(' ')}>
      <div className={[classes.treatBlk, classes.deep, classes.t2].join(' ')}>
        <p className={classes.treatName}><span>Deep</span> Tissue</p>
        <p>50 min: $95<br/>
        80 min: $135</p>          
      </div>

      <div className={[classes.treatBlk, classes.warm, classes.t2].join(' ')}>
        <p className={classes.treatName}><span>Warm</span> Stone</p>
        <p>50 min: $95<br/>
        80 min: $135</p>     
      </div>

      {/* <div className={classes.clearfix} /> */}

      <div className={[classes.treatBlk, classes.swed, classes.m3].join(' ')}>
        <p className={classes.treatName}>Swedish</p>
        <p>50 min: $90<br/>
        80 min: $125</p>
      </div>

      <div className={[classes.treatBlk, classes.pre, classes.m3].join(' ')}>
        <p className={classes.treatName}>Prenatal</p>
        <p>50 min: $85<br/>
        80 min: $120</p>
      </div>
      <div className={classes.clearfix} />

      <div className={classes.addons}>
        <div className={classes.aoTitle}><span>Add Ons</span></div>
        <div className={[classes.treatBlk, classes.myo2, classes.l2].join(' ')}>
          <p className={[classes.treatName, classes.bmargin0].join(' ')}><span>Myofascial</span> Release &middot; $10</p>
          <p>(Tool Assisted)</p>
        </div>

        <div className={[classes.treatBlk, classes.cup2, classes.l2].join(' ')}>
          <p className={classes.treatName}><span>Cupping</span> Therapy &middot; $10</p>
        </div>
      </div>
    </div>

    <div className={[classes.col, classes.left, classes.image].join(' ')}><img src={massageGrid} alt="Warm stone massage - Rejuvenation Orange Beach, AL" /></div>
  </div>
);

const browDesc="Rejuvenation Spa provides non-invasive treatments that give your brows and lashes the perfect look -- darker, thicker, and beautifully full.";
const browHd=(<>Non-Invasive Brow<br className={classes.size}/> <AndSvg /> Lash Treatments</>);
const browContent=(
  <div className={[classes.med2Def, classes.bcWrap].join(' ')}>
    <div className={[classes.col, classes.left, classes.grid70, classes.one, classes.treatBlk].join(' ')}>
      <p className={classes.treatName}><span>Brow</span> Lamination</p>
      <p>Brow lamination is a non-invasive treatment that makes your brows appear more full, slicked up with high shine. It lays down unruly brows, and gives you a 24/7 perfected brow appearance. This is a great alternative to microblading, and can be done in conjunction with brow tinting. Effects can last up to 2 months, and can be repeated when necessary.</p>
    </div>
    <div className={classes.clearfix}/>

    <div className={[classes.col, classes.left, classes.grid37, classes.image].join(' ')}><img src={eyebrows} alt="Beautifully full eyebrows and lashes" /></div>

    <div className={[classes.col, classes.right, classes.grid62, classes.two, classes.treatBlk].join(' ')}>
      <p className={classes.treatName}><span>Lash</span> Lifting</p>
      <p>Lash lifting gives your eyelashes a beautiful curl, and makes your eyes look younger and more open. It also makes your lashes appear darker, thicker, and fuller. This quick, painless treatment will have you bright eyed in no time. It can also be done in conjunction with lash tinting, for a more dramatic affect. Treatment typically lasts 6-8 weeks.</p>
    </div>

    <div className={[classes.col, classes.grid60, classes.three].join(' ')}>
      <p className={classes.disc}><span>Brow</span> and <span>Facial Waxing</span> are also available</p>
      <p className={classes.small}>(Can be requested individually, or combined with other treatments)</p>
    </div>
  </div>
);


const serviceItems = [
  {id:1, title:"Advanced Skin Care", headline:[skinHd], image:[skincareHero], content:[skinContent], link:"skin-care", intro:[skinIntro], subtitle:[skinSub], pageTitle: "Facials & Skin Care", pageDesc:[skinDesc], imgAlt:'Custom face mask for healthy skin' },
  {id:2, title:"Laser Treatments", headline:"TriPollar Apollo Laser Treatments", image:[laserHero], content:[laserContent], link:"laser-treatments", subtitle:[laserSubtitle], intro:[laserIntro], pageTitle:'Skin Tightening Laser Treatments', pageDesc:[laserDesc], imgAlt: 'Ladies with smooth skin after laser treatment'},
  {id:3, title:"Cosmetic Enhancements", image:[cosmeticHero], content:[cosmeticContent], link:"cosmetic-enhancements", nickname:"enhance", intro:[cosmeticIntro], subtitle:[cosSubtitle], pageTitle:'BOTOX®, Juvéderm®, and V Soft Line® Threads', pageDesc:[cosDesc], imgAlt: "Woman inspecting skin after cosmetic injection"},
  {id:4, title:"Massage Therapy", headline:"Licensed, Professional Massage Therapy", image:[massageHero], content:[massageContent], link:"massage-therapy", subtitle:[massageSubtitle], pageDesc:"Check out the massage therapy options at Rejuvenation Med Spa, and book an appointment today.", imgAlt: "Client preparing for massage - Rejuvenation Med Spa"},
  {id:5, title:"Brow and Lash Services", headline:[browHd], image:[browHero], content:[browContent], link:"brow-lash-treatments", nickname:"browLash", pageTitle: 'Brow Lamination & Lash Lifting', pageDesc:[browDesc], imgAlt:'Eyebrow and eyelash enhancements'},
];
export default serviceItems;
import React, { Component } from 'react';
import classes from "./Tabs.module.scss";

class Tabs extends Component {
  render() {
    return (
      <div className={[classes.TabSection, classes[this.props.class2], classes[this.props.where], classes[this.props.stack]].join(' ')}>
        <div className={[classes.tabWrap, classes[this.props.tnavWrap], classes.twNav].join(' ')}>
          <ul className={[classes.tabNav, classes[this.props.navAlign]].join(' ')} data-uk-tab="connect: #projectTabs; animation:uk-animation-fade">
            <li className={classes.tn1}><button><span>{this.props.tab1title}</span></button></li>
            <li className={classes.tn2}><button><span>{this.props.tab2title}</span></button></li>
            {this.props.tab3title ? <li className={classes.tn3}><button><span>{this.props.tab3title}</span></button></li>: null}
          </ul>
          {/* {this.props.navAlign === "dec" ? <p className={classes.cat}>Select a Category</p> : null} */}
        </div>

        <div className={[classes.tabWrap, classes[this.props.tcontentWrap]].join(' ')}>
          <ul id="projectTabs" className={[classes.tabContent, "uk-switcher"].join(' ')}>
            <li className={[classes.tcBlock, classes.tc1].join(' ')}>
              {this.props.tab1content}
            </li>

            <li className={[classes.tcBlock, classes.tc2].join(' ')}>
              {this.props.tab2content}
            </li>

            {this.props.tab3content ? 
              <li className={[classes.tcBlock, classes.tc3].join(' ')}>
                {this.props.tab3content}
              </li>
            : null}

          </ul>
        </div>
        
        
        {this.props.extraContent ? <div className={classes.extraContent}>{this.props.extraContent}</div> : null}
      </div>
    );
  }
}
Tabs.defaultProps = {
  class2: "defaultTabs",
  tnavWrap: "medDef",
  tcontentWrap: "medDef",
  navAlign: "centerNav"
}

export default Tabs;
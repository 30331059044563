import React from "react";
// import classes from "./Shared.module.scss";

const andSvg = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 366.000000 355.000000" preserveAspectRatio="xMidYMid meet">
      <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
      <g transform="translate(0.000000,355.000000) scale(0.100000,-0.100000)" stroke="none">
      <path d="M1195 3532 c-167 -79 -357 -349 -420 -597 -32 -123 -43 -304 -25
      -420 26 -176 28 -209 19 -268 -19 -116 -62 -174 -248 -332 -209 -176 -405
      -449 -476 -660 -47 -141 -60 -354 -29 -503 71 -344 319 -611 670 -719 83 -25
      101 -27 274 -27 176 0 189 1 270 28 186 61 322 150 562 365 270 243 292 261
      331 271 27 8 87 8 205 0 393 -25 522 -23 487 8 -6 5 -142 24 -303 43 -243 28
      -292 36 -290 49 5 23 152 124 271 185 336 174 703 200 1072 75 49 -16 90 -28
      93 -26 10 10 -13 52 -50 92 -45 49 -97 69 -256 101 -122 24 -403 24 -525 0
      -204 -41 -441 -148 -617 -278 -52 -38 -105 -72 -118 -76 -29 -7 -119 37 -244
      119 -299 196 -535 473 -722 846 -88 175 -128 279 -121 312 3 16 73 131 154
      257 347 536 399 654 388 868 -7 137 -45 222 -124 275 -50 34 -168 40 -228 12z
      m118 -183 c72 -20 83 -70 47 -224 -54 -233 -127 -397 -248 -557 -136 -181
      -167 -218 -178 -218 -16 0 -35 183 -35 339 0 158 24 301 72 419 50 127 107
      192 194 226 73 27 96 30 148 15z m-393 -1529 c211 -483 498 -816 873 -1009 50
      -26 111 -55 135 -64 l42 -17 -62 -53 c-209 -176 -332 -264 -454 -325 -142 -71
      -268 -102 -408 -102 -217 1 -405 108 -498 285 -47 90 -66 158 -78 272 -29 278
      73 658 254 947 59 93 135 190 143 182 3 -3 27 -55 53 -116z"/>
      </g>
    </svg>
  );
}
export default andSvg;
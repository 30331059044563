import React from "react";
import classes from "./Shared.module.scss";

const email = (props) => {
  return (
    <a className={[classes.Email, classes[props.where]].join(' ')} href="mailto:rejuvenationmedspa@ymail.com">{props.text}</a>
  );
}
email.defaultProps = {
  text: "rejuvenationmedspa@ymail.com"
}
export default email;
import React from "react";
// import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Social from "../../components/Social/Social";
// import Drawer from "../Header/Drawer";

const footer = (props) => {
  return (
    <footer className={[classes.Footer, classes[props.FooterType], classes.fullRow].join(' ')}>
      <div className={classes.ftWrapServe}>
        <div className={[classes.med2Def, classes.v1].join(' ')}>
          <div className={[classes.col, classes.left].join(' ')}>
            <div className={classes.item}><p><span className={classes.cat}>Hours:</span> Monday-Friday: 10am-5pm</p></div>
            <div className={classes.item}><p><span className={classes.cat}>Phone:</span> <Phone where="footer" /></p></div>
            <div className={classes.item}><p><span className={classes.cat}>Email:</span> <Email where="footer" /></p></div>
            <div className={classes.item}><p><span className={classes.cat}>Follow:</span> <Social where="footer" /></p></div>
          </div>

          <div className={[classes.col, classes.right].join(' ')}>
            {/* <div className={classes.item}><p><span className={classes.cat}>Follow:</span> <Social where="footer" /></p></div> */}
            <div className={classes.item}>
              <p className={classes.cat}>Location: </p>
              <p className={classes.ap1}>4776 Main Street, Suite L201 <br/>Orange Beach, AL 36561<br/>
                <span className={classes.smText}>The Wharf, 2nd floor (above Lucca)</span>
              </p>
              <p>
                <a 
                  href="https://www.google.com/maps/dir//4776+Main+St+l201+Orange+Beach,+AL+36561/@30.2937775,-87.6281243,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889a05dcadda5a2b:0x4689bcedbd518070!2m2!1d-87.6281243!2d30.2937775" 
                  target="_blank" 
                  rel="noreferrer"
                  className={classes.textLink}>Get Directions
                </a>
              </p>
            </div>
          </div>

          <div className={[classes.col, classes.mid].join(' ')}>
            <div className={[classes.item, classes.i1].join(' ')}>
              <p className={classes.em}>Most services by appointment only. 24 hours notice required to avoid cancellation charges. Prices are subject to change without notice.
              </p>
            </div>
            <div className={[classes.item, classes.i2].join(' ')}>
              {/* <p className={classes.cat}>Botox® &amp; Filler Appointments</p> */}
              <p className={classes.cat}>Botox®, Filler, &amp;<br/> Thread Lift Appointments</p>
              <p>Wednesdays: 2:30pm-8pm (and some Fridays)</p>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.btmFooter].join(' ')}>
        <div className={classes.med4Def}>
          <p className={[classes.copy, classes.hc1].join(' ')}>© {(new Date().getFullYear())} Rejuvenation Med Spa.</p> <p className={[classes.copy, classes.hc2].join(' ')}> Design by <a href="http://hsdesignhouse.com" target="_blank" rel="noopener noreferrer">High Seas Design House.</a></p>
        </div>
      </div>
    </footer>
  );
}

export default footer;






import React from 'react';
import classes from "./Header.module.scss";
// import styles from "../Footer/Footer.module.scss";
import Drawer from '@mui/material/Drawer';
// import Button from '@mui/material/Button';
import Button from '@mui/base/ButtonUnstyled';
import ClearIcon from '@mui/icons-material/Clear';
import NavItems from "./NavItems";
import Phone from "../../components/Shared/Phone";
import Email from "../../components/Shared/Email";
import Social from "../../components/Social/Social";

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <NavItems navClass="SideNav" />
    </div>
  );

  return (
    <div className={[classes.NavDrawer].join(' ')}>{/* , styles.ftDrawer */}
      <Button onClick={toggleDrawer('left', true)} className={classes.mBtnWrap}>
        <div className={[classes.mobileNavBtn].join(' ')}>
          <span />
          <span />
          <span />
        </div>
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}

        <div className={classes.drContact}>
          <div className={classes.dcItem}><p><span className={classes.cat}>Phone:</span> <Phone where="drawer" /></p></div>
          <div className={classes.dcItem}><p><span className={classes.cat}>Email:</span> <Email where="drawer" /></p></div>
          <div className={classes.dcItem}><p><span className={classes.cat}>Hours:</span> Monday-Friday: 10am-5pm</p></div>
          <div className={classes.dcItem}>
            <p className={classes.ap1}>
              <span className={classes.cat}>Location: </span><br/>
                4776 Main Street, Suite L201 <br/>Orange Beach, AL 36561<br/>
                <span className={classes.smText}>The Wharf, 2nd floor (above Lucca)</span>
            </p>
            <p>
              <a 
                href="https://www.google.com/maps/dir//4776+Main+St+l201+Orange+Beach,+AL+36561/@30.2937775,-87.6281243,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x889a05dcadda5a2b:0x4689bcedbd518070!2m2!1d-87.6281243!2d30.2937775" 
                target="_blank" 
                rel="noreferrer"
                className={classes.textLink}>Get Directions
              </a>
            </p>
          </div>
          <div className={classes.dcItem}><p><span className={classes.cat}>Follow:</span> <Social where="drawer" /></p></div>
        </div>
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;
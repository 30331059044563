import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import classes from './Products.module.scss';
// import image1 from "../../assets/images/products.jpg";
import combo from "../../assets/images/pex4.jpg";
import image2 from "../../assets/images/products.jpg";
import image2b from "../../assets/images/productsMobile.jpg";

const products = () => {
  return (
    <div className={classes.ProductsPage}>
      <Helmet>
        <title>Amazing Skin Care & Specialty Products | Rejuventation Med Spa Orange Beach, AL</title>
        <meta name="description" content="Rejuvenation Med Spa carries Obagi, SkinCeuticals, and other high-end pharmaceutical skin care products. Shop with us at The Wharf, in Orange Beach, AL." />
        
      </Helmet>

      <div className={classes.ServiceHeaderSimple}><div className={classes.medDef}><h1>Visit us to shop the ultimate <br/> <span>in pharmaceutical skin care!</span></h1></div></div>
      <div className={classes.fullImg}><div className={classes.insideXl}><img src={combo} alt="Skin care products at Rejuvenation Med Spa" /></div></div>

      <div className={classes.pageWrap}>
        <div className={classes.innerWrap}>
          <div className={classes.text0}>
            <p className={classes.after}>We offer the full line of <b>Obagi.</b> We also 
            carry <b>SkinCeuticals</b> Specialty Products, <b>Nuderm, C-System,</b> and <b>360</b> for age reversal, skin brightening, and maintenence.</p>
          </div>        
        </div>

        <div className={[classes.fullRow, classes.imagePlus].join(' ')}>
          <div className={[classes.medDef, classes.mediaCust].join(' ')}>          
            <div className={[classes.col, classes.left, classes.grid36].join(' ')}>
              <p className={classes.after}>Our team is committed to providing excellent service, and high quality products. If you are not satisfied with a <b>non-perscription</b> product, we 
              will accept a return <b>within 7 days</b> of purchase.</p>
    
              <p className={classes.ogShow}><Link to="/contact" className={classes.textLink}>Contact us</Link> for information about shipping your skin care products!</p>
            </div>

            <div className={[classes.col, classes.right, classes.grid73].join(' ')}>
              <img src={image2} alt="ph alt" className={classes.fw} />
              <img src={image2b} alt="ph alt" className={classes.mobile} />
            </div>
          </div>
        </div>

        <div className={classes.banner}><p><Link to="/contact" className={classes.textLink}>Contact us</Link> for information about shipping your skin care products!</p></div>
      </div>
    </div>
  );
}
export default products;
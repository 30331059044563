import React from "react";
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";

const navItems = (props) => {
  return (
    <ul className={[classes[props.navClass], props.addClass].join(' ')}>
      <li className={[classes.navItem, classes.XmobOnly].join(' ')}><Link to="/">Home</Link></li>
      <li className={[classes.navItem, classes.withDrop].join(' ')}><Link to="#">Services</Link>
        {/* <div className={[classes.custDrop, "uk-navbar-dropdown"].join(' ')} data-uk-drop="boundary:!nav;boundary-align:true;pos:bottom-justify;"> */}
        <div className={[classes.custDrop, "uk-navbar-dropdown"].join(' ')} data-uk-drop="boundary:!nav;boundary-align:true;pos:bottom-justify;">
          <ul className={[classes.ddNav, "uk-nav uk-navbar-dropdown-nav"].join(' ')}>
            <li className={classes.dropLi}><Link to="/skin-care">Advanced Skin Care</Link></li>
            <li className={classes.dropLi}><Link to="/laser-treatments">Laser Treatments</Link></li>
            <li className={classes.dropLi}><Link to="/cosmetic-enhancements">Cosmetic Enhancement</Link></li>
            <li className={classes.dropLi}><Link to="/brow-lash-treatments">Brow and Lash Services</Link></li>
            <li className={classes.dropLi}><Link to="/massage-therapy">Massage Therapy</Link></li>
          </ul>
        </div>
      </li>
      <li className={classes.navItem}><Link to="/products">Products</Link></li>
      <li className={classes.navItem}><Link to="/contact">Contact</Link></li> 
    </ul>
  );
}
export default navItems;
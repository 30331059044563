import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
import NavItems from "./NavItems";
import Drawer from "./Drawer";
import Phone from "../Shared/Phone";
// import logo from "../../assets/images/text.png";
import logo from "../../assets/images/rejuvenation.jpg";

class Header extends Component {
  render() {
    return (
      <header className={[classes.Header, classes[this.props.HeaderType]].join(' ')} data-uk-sticky={["sel-target:.hdRows;cls-active:uk-navbar-sticky;cls-inactive:"] + classes.statNav + [";animation:uk-animation-slide-top;top:150;media:768"]}>
        {/* data-uk-sticky={["sel-target:.hdRows;cls-active:uk-navbar-sticky;cls-inactive:"] + classes.statNav + [";animation:uk-animation-slide-top;top:150;media:768"]} */}
        <div className={[classes.fullRow, classes.topRows].join(' ')}>
          <div className={[classes.col, classes.logoBlk].join(' ')}>
            <Link to="/" className={classes.logoLink}>
              <img src={logo} alt="Rejuvenation Med Spa Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} />
            </Link>
          </div>
          <div className={classes.mobNav}><Drawer /></div>
        </div>
        
        <div className={[classes.fullRow, classes.hdRows].join(' ')}>
          <nav className={[classes.custom, classes.hdrWrap, "uk-navbar-container"].join(' ')} data-uk-navbar="dropbar:true;">
            <div className={[classes.col, classes.left].join(' ')}>
              <NavItems navClass="navBarCustom" addClass="uk-navbar-nav" />
            </div>

            <div className={[classes.col, classes.logo, classes.logoLine].join(' ')}>
              <Link to="/" className={classes.logoLink}>
                <img src={logo} alt="Rejuvenation Med Spa Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} />
              </Link>
            </div>
            
            <div className={[classes.col, classes.right].join(' ')}>
              <p>Book An Appointment<span> Today</span>:<br className={classes.xsShow} /> <Phone where="ifbar" /></p>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
export default Header;
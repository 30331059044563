import React, { Component } from "react";
import { Helmet } from "react-helmet";
import classes from './Services.module.scss';
import { Link } from "react-router-dom";
import ServiceItems from "./ServiceLoop";

class Services extends Component {
  render() {
    let current = this.props.title;
    let specify = this.props.link;
    if (this.props.nickname) {
      specify = this.props.nickname;
    } 

    let pageTitle = this.props.pageTitle != null ? this.props.pageTitle : this.props.title;
    let imgAlt = this.props.imgAlt != null ? this.props.imgAlt : [this.props.title + " at Rejuvenation Spa"];

    const others = ServiceItems.map((item) =>
      <li className={[classes.osItem, [current === item.title ? [classes.current] : [classes.addit]]].join(' ')}>
        <Link to={"/" + [item.link]} className={classes.textLink}>
          {item.title}
        </Link>
      </li>
    );

    return (
      <div className={[classes.ServicePage, classes[specify]].join(' ')}>
        <Helmet>
          <title>{[pageTitle] + " at Rejuvenation Med Spa in Orange Beach, AL"}</title>
          <meta name="description" content={this.props.pageDesc} />
        </Helmet>
      
        {this.props.intro ? 
          <div className={classes.ServiceHeaderSimple}>
            <div className={classes.medDef}>
              {this.props.headline ? <h1>{this.props.headline}</h1> : <h1>{this.props.title}</h1>}
              {this.props.subtitle ? <h2>{this.props.subtitle}</h2> : null}
            </div>
          </div>
        : null}
          
        <div className={[classes.fullRow, classes.ServiceImgStack, classes[specify], [this.props.intro ? [classes.withText] : [classes.withTitle]]].join(' ')}>
          <div className={classes.insideN}>
            <div className={[classes.col, classes.grid38, classes.content].join(' ')}>
              {this.props.intro ? [this.props.intro]
              : <div>
                {this.props.headline ? <h1>{this.props.headline}</h1> : <h1>{this.props.title}</h1>}
                {this.props.subtitle ? <h2>{this.props.subtitle}</h2> : null}
              </div>}
            </div> 
            <div className={[classes.col, classes.grid65, classes.imageSide, classes.left].join(' ')}><img src={this.props.image} alt={imgAlt} /></div>
            <div className={[classes.imgMob, classes.class2].join(' ')} style={{ backgroundImage: "url(" + this.props.image + ")"}}></div>
          </div>
        </div>

        <div className={[classes.fullRow, classes.ServiceBody, classes[this.props.stack]].join(' ')}>
          {this.props.content ? <div className={classes.serviceContent}>{this.props.content}</div> : null}
        </div>

        <div className={classes.xxlColor1Lt}><div className={classes.xxlWrap}>
        <div className={[classes.fullRow, classes.osWrap].join(' ')}>
          <div className={[classes.cfBox].join(' ')}>
            <p className={classes.cat}>Our Policy</p>
            <p>Consultation may be required to determine if you are a candidate for treatment, &amp; the number of treatments needed to reach desired results.</p>
          </div>

          <div className={[classes.fullRow, classes.OtherServices, classes[this.props.stack]].join(' ')}>
            <div className={classes.list}>
              <div className={classes.mtitle}><p className={classes.cat}>Other Services</p></div>
              <ul>
                {others}
              </ul>
            </div>

            <div className={classes.titleRow}><p className={classes.title}>Other Services</p></div>
            
            <div className={[classes.colX, classes.grid60, classes.right, classes.reviews].join(' ')}>
              <div className={classes.rItem}>
                <p className={classes.quote}>Kimberly Beasley knows her stuff.<br/> A true pro. Fantastic results.<br/> I'm blown away. Facials and treatments. Highly recommend!!</p>
                <p className={classes.cat}>- Melissa W.</p>
              </div>
            </div>
          </div>
        </div>
        </div></div>
      </div>
    );
  }
}
Services.defaultProps = {
  stack: "stackRight"
};
export default Services;
import React, {Component} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import classes from './App.module.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ToTopBtn from "./components/ScrollToTop/ToTopBtn";
import Home2 from './hoc/Home/Home2';
import Contact from './hoc/Contact/Contact';
import Services from './hoc/Services/Services';
import serviceItems from './hoc/Services/ServiceLoop';
import Products from './hoc/Products/Products';

export function withRouter( Child ) {
  return ( props ) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child { ...props } navigate={ navigate } location={ location } />;
  }
}

class App extends Component {
  render() {
    const serviceLoop = serviceItems.map((item) =>
      <Route
        path={"/" + [item.link]}
        key={item.id}
        element={ 
          <Services
            id={item.id}
            link={item.link}
            title={item.title}
            headline={item.headline}
            subtitle={item.subtitle}
            content={item.content}
            image={item.image}
            nickname={item.nickname}
            addClass={item.addClass}
            intro={item.intro}
            stack={item.stack}
            pageTitle={item.pageTitle}
            pageDesc={item.pageDesc}
            imgAlt={item.imgAlt}
          />
        }
      />
    );
    
    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home2 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<Products />} />
        {/* <Route path="*" component={<Fallback />} /> */}
        {serviceLoop}
      </Routes>
    );


    const FootStyle = () => {
      if (window.location.pathname === '/contact') {
        return (<Footer FooterType="plain" />);
      } else if (window.location.pathname === '/' || window.location.pathname === '/home2') {
        return (<Footer FooterType="HomeStyle" />);
      }
      return (<Footer FooterType="DefaultStyle" />);
    }
    const SneakyFooter = withRouter(FootStyle);
    const pathName = window.location.pathname.replace('-','');
    const wrapClass = pathName.replace('/', '');
    

    return (
      <div className={[classes.rejuvWrap, classes[wrapClass]].join(' ')}>
        <Header />
        <main className={classes.MainContent}>
          {routes}
        </main>
        <ToTopBtn />
        <SneakyFooter />
      </div>
    );
  }
}
export default App;